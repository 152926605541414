<template>
  <div id="team">
    <div class="teamList">
      <!--团队区域-->
      <div class="ListTop">
        <h1>{{ ListTitle }}</h1>
        <i
          class="ivu-icon ivu-icon-ios-add-circle-outline"
          @click="AddTeamBox = !AddTeamBox"
          v-if="userType == 0 ? false : change == -1 ? false : true"
        ></i>
      </div>
      <div class="ListContent">
        <div class="itemBox" @click="selectAll">
          <div
            class="Top"
            :style="
              change == -1
                ? $store.state.darkTheme
                  ? 'backgroundColor:#3C3D43'
                  : 'backgroundColor:#d6d6d6'
                : $store.state.darkTheme
                ? 'backgroundColor:#1F1F1F'
                : 'backgroundColor:#f0f0f0'
            "
          >
            <h1>全部成员</h1>
            <h2 v-if="totalPopulation !== 0">{{ totalPopulation }}人</h2>
          </div>
        </div>
        <div class="itemBox" v-if="userType != 0" @click="selectOneself">
          <div class="Top Item" :id="'TeamList' + userInfo.team_id">
            <h1>{{ userInfo.team_name ? userInfo.team_name : '当前团队' }}</h1>
            <h2 v-if="totalPopulation !== 0">{{ totalPopulation }}人</h2>
          </div>
        </div>
        <teamTree
          @selectTeam="selectTeam"
          v-if="DestroyTree == true"
          ref="teamTreeComponent"
        ></teamTree>
      </div>
    </div>
    <!--团队成员区域-->
    <div class="teamMember">
      <div class="MemberTop">
        <div style="display: flex; align-items: center">
          <h1>
            {{ change == -1 ? '所有飞手' : teamTitle }}
            <span>共{{ MemberList.length }}人</span>
          </h1>
          <Tooltip placement="bottom" max-width="250" v-show="change != -1">
            <Icon type="md-more" />
            <div slot="content">
              <p>剩余可分配云空间：{{ remainingSpace }}MB</p>
              <p>剩余可添加飞手数量：{{ remainingUserNum }}</p>
              <p>剩余可绑定设备数量：{{ remainingDevNum }}</p>
              <p>云空间总容量：{{ sumSpace }}MB</p>
              <p>可添加飞手总数量：{{ sumUserNum }}</p>
              <p>可绑定设备总数量：{{ sumDevNum }}</p>
            </div>
          </Tooltip>
        </div>
        <div>
          <Input
            placeholder="搜索成员"
            v-model="searchtext"
            search
            enter-button
            @on-search="getTeamStaffData"
          />
        </div>
        <div>
          <button
            :class="
              $CSXF ? 'team-member-button' : 'team-member-button borderRadius'
            "
            style="background: #f56c6c; margin-right: 20px"
            @click="deleteTeamShow = !deleteTeamShow"
            v-if="change != -1 && change != userInfo.team_id"
          >
            删除团队
          </button>
          <button
            :class="
              $CSXF ? 'team-member-button' : 'team-member-button borderRadius'
            "
            @click="AddMember = !AddMember"
            style="background: #4c98ff"
            v-if="change != -1"
          >
            新增用户
          </button>
        </div>
        <!-- <i
          class="ivu-icon ivu-icon-ios-add-circle-outline"
          @click="AddMember = !AddMember"
          v-if="userType == 0 ? false : true"
        ></i> -->
      </div>
      <div class="MemberContent">
        <Table :loading="loading" :columns="columns" :data="MemberList"></Table>
      </div>
    </div>
    <!--弹框区-->
    <!--添加团队-->
    <Modal
      v-model="AddTeamBox"
      width="638"
      class-name="team-center-modal"
      @on-cancel="cancelAddTeam"
    >
      <p slot="header" style="text-align: center">
        <span>添加团队</span>
      </p>
      <div style="padding: 10px 30px">
        <Form
          ref="Team"
          :model="Team"
          :rules="Team_ruleInline"
          label-position="top"
        >
          <div class="AddTeamContent">
            <div class="right">
              <FormItem label="团队名称" prop="team_name">
                <Input v-model.trim="Team.team_name"></Input>
              </FormItem>
              <FormItem label="管理员账号" prop="account">
                <Input v-model.trim="Team.account"></Input>
              </FormItem>
              <FormItem
                v-if="$CSTJ"
                label="管理员警航执照"
                prop="police_license"
              >
                <Input v-model="Team.police_license"></Input>
              </FormItem>
              <FormItem label="可开账号数量" prop="create_user_num">
                <InputNumber
                  :min="1"
                  :max="remainingUserNum"
                  :precision="0"
                  :active-change="false"
                  :placeholder="'剩余可分配飞手' + remainingUserNum + '人'"
                  v-model.trim="Team.create_user_num"
                  style="width: 250px"
                ></InputNumber>
              </FormItem>
              <FormItem label="云空间容量/MB" prop="storage_space">
                <InputNumber
                  :min="0"
                  :max="remainingSpace"
                  :active-change="false"
                  :precision="0"
                  :placeholder="'剩余可分配云空间容量' + remainingSpace + 'MB'"
                  v-model.trim="Team.storage_space"
                  style="width: 250px"
                ></InputNumber>
              </FormItem>
              <FormItem label="管理员头像" prop="head_icon">
                <div
                  class="upload-list"
                  v-for="(item, idx) in uploadList"
                  :key="idx"
                >
                  <template v-if="item.status === 'finished'">
                    <img :src="item.url" />
                    <div class="upload-list-cover">
                      <Icon
                        type="ios-trash-outline"
                        @click="handleRemove(item)"
                      ></Icon>
                    </div>
                  </template>
                </div>
                <Upload
                  v-if="uploadList.length === 0"
                  ref="upload"
                  :show-upload-list="false"
                  :format="['jpg', 'jpeg', 'png']"
                  :max-size="2048"
                  :on-format-error="handleFormatError"
                  :on-exceeded-size="handleMaxSize"
                  :before-upload="uploadBefore"
                  type="drag"
                  action
                  style="display: inline-block; width: 58px"
                >
                  <div style="width: 58px; height: 58px; line-height: 58px">
                    <Icon type="ios-camera" size="20"></Icon>
                  </div>
                </Upload>
              </FormItem>
            </div>
            <div class="left">
              <FormItem label="管理员名称" prop="name">
                <Input v-model.trim="Team.name"></Input>
              </FormItem>
              <FormItem label="密码" prop="password">
                <Input v-model.trim="Team.password"></Input>
              </FormItem>
              <!-- <FormItem label="管理员电话号码"
                        v-if="$public"
                        prop="mobile_phone">
                <Input v-model="Team.mobile_phone"></Input>
              </FormItem> -->
              <FormItem label="可绑定设备数量" prop="bind_dev_num">
                <InputNumber
                  :min="0"
                  :max="remainingDevNum"
                  :precision="0"
                  :active-change="false"
                  :placeholder="'剩余可绑定设备数量' + remainingDevNum + '台'"
                  v-model.trim="Team.bind_dev_num"
                  style="width: 250px"
                ></InputNumber>
              </FormItem>
              <FormItem label="是否设置有效期">
                <RadioGroup v-model="Team.radio">
                  <Radio :label="1">是</Radio>
                  <Radio :label="0">否</Radio>
                </RadioGroup>
              </FormItem>
              <FormItem label="设置有效期" v-show="Team.radio == 1">
                <DatePicker
                  type="date"
                  format="yyyy-MM-dd"
                  :value="Team.date"
                  @on-change="addDate"
                  style="width: 250px"
                ></DatePicker>
              </FormItem>
            </div>
          </div>
        </Form>
      </div>
      <div style="text-align: center" slot="footer">
        <Button
          type="info"
          @click="AddTeam_ok"
          style="width: 200px; letter-spacing: 8px"
        >
          确认
        </Button>
      </div>
    </Modal>
    <!--添加团队成员-->
    <Modal
      v-model="AddMember"
      @on-cancel="cancelAddMember"
      class-name="AddMember-center-modal"
    >
      <p slot="header" style="text-align: center">
        <span>添加团队成员</span>
      </p>
      <div style="padding: 10px 30px">
        <!--新建账号-->
        <Form
          ref="NewMember"
          :model="NewMember"
          :rules="NewMember_ruleInline"
          label-position="top"
        >
          <FormItem label="用户名" prop="name">
            <Input v-model.trim="NewMember.name"></Input>
          </FormItem>
          <FormItem label="账号" prop="account">
            <Input v-model.trim="NewMember.account"></Input>
          </FormItem>
          <FormItem label="密码" prop="password">
            <Input v-model.trim="NewMember.password"></Input>
          </FormItem>
          <!-- <FormItem label="电话号码"
                    v-if="$public"
                    prop="mobile_phone">
            <Input v-model="NewMember.mobile_phone"></Input>
          </FormItem> -->
          <FormItem v-if="$CSTJ" label="警航编号" prop="police_license">
            <Input v-model="NewMember.police_license"></Input>
          </FormItem>
          <FormItem label="是否可以查看下级团队" prop="permission">
            <i-switch v-model="NewMember.permission" />
          </FormItem>
          <FormItem label="是否设置有效期">
            <RadioGroup v-model="NewMember.radio">
              <Radio :label="1">是</Radio>
              <Radio :label="0">否</Radio>
            </RadioGroup>
            <DatePicker
              type="date"
              v-show="NewMember.radio == 1"
              style="margin-left: 40px"
              placeholder="设置有效期"
              format="yyyy-MM-dd"
              :value="NewMember.date"
              @on-change="newDate"
            ></DatePicker>
          </FormItem>
          <FormItem label="头像" prop="head_icon">
            <div
              class="upload-list"
              v-for="(item, idx) in uploadList"
              :key="idx"
            >
              <template v-if="item.status === 'finished'">
                <img :src="item.url" />
                <div class="upload-list-cover">
                  <Icon
                    type="ios-trash-outline"
                    @click="handleRemove(item)"
                  ></Icon>
                </div>
              </template>
            </div>
            <Upload
              v-if="uploadList.length == 0"
              ref="upload"
              :show-upload-list="false"
              :format="['jpg', 'jpeg', 'png']"
              :max-size="2048"
              :on-format-error="handleFormatError"
              :on-exceeded-size="handleMaxSize"
              :before-upload="uploadBefore"
              multiple
              type="drag"
              action
              style="display: inline-block; width: 58px"
            >
              <div style="width: 58px; height: 58px; line-height: 58px">
                <Icon type="ios-camera" size="20"></Icon>
              </div>
            </Upload>
          </FormItem>
        </Form>
      </div>
      <div style="text-align: center" slot="footer">
        <Button
          type="info"
          @click="AddMember_ok"
          style="width: 200px; letter-spacing: 8px"
          >确认</Button
        >
      </div>
    </Modal>
    <!-- 编辑成员信息 -->
    <Modal
      v-model="compileMemberData"
      class-name="AddMember-center-modal"
      @on-cancel="cancelAddTeam"
    >
      <p slot="header" style="text-align: center">
        <span>编辑用户信息</span>
      </p>
      <div style="padding: 10px 30px">
        <Form
          ref="EditMemberData"
          :model="EditMemberData"
          :rules="EditMemberData_ruleInline"
          label-position="top"
        >
          <FormItem
            label="修改头像"
            prop="head_icon_Edit"
            v-if="!EditManagerShow"
          >
            <!-- <div
              class="upload-list"
              v-for="(item, idx) in uploadList2"
              :key="idx"
            > -->
            <!-- <template v-if="item.status === 'finished'"> -->
            <!-- <img :src="EditMemberData.head_icon_Edit" />
            <div class="upload-list-cover">
              <Icon
                type="ios-trash-outline"
                @click="handleRemove_Edit(item)"
              ></Icon>
            </div> -->
            <!-- </template>
            </div> -->
            <Upload
              :show-upload-list="false"
              :format="['jpg', 'jpeg', 'png']"
              :max-size="2048"
              :on-format-error="handleFormatError"
              :on-exceeded-size="handleMaxSize"
              :before-upload="uploadBefore_Edit"
              multiple
              type="drag"
              action
              style="display: inline-block"
            >
              <!-- <div style="width: 58px; height: 58px; line-height: 58px">
                <Icon type="ios-camera" size="20"></Icon>
              </div> -->
              <div
                style="
                  width: 80px;
                  height: 80px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: #e0e0e0;
                "
              >
                <img
                  :src="EditMemberData.head_icon_Edit"
                  style="width: 100%"
                  alt=""
                />
              </div>
            </Upload>
          </FormItem>
          <FormItem label="修改用户名" prop="name">
            <Input v-model.trim="EditMemberData.name"></Input>
          </FormItem>
          <!-- <FormItem label="修改电话号码"
                    v-if="$public"
                    prop="mobile_phone">
            <Input v-model="EditMemberData.mobile_phone"></Input>
          </FormItem> -->
          <FormItem v-if="$CSTJ" label="修改警航编号" prop="police_license">
            <Input v-model.trim="EditMemberData.police_license"></Input>
          </FormItem>
          <FormItem
            label="查看下级团队信息"
            prop="permission"
            v-if="!EditManagerShow"
          >
            <i-switch v-model="EditMemberData.permission" />
          </FormItem>
          <div
            v-if="EditManagerShow"
            v-show="CheckedEditMember != userInfo.user_id"
          >
            <FormItem label="云储存空间:MB" prop="storage_space">
              <!-- <Input v-model="EditMemberData.storage_space"></Input> -->
              <InputNumber
                controls-outside
                :min="useSpace"
                :precision="0"
                :active-change="false"
                v-model.trim="EditMemberData.storage_space"
              ></InputNumber>
              <span style="font-size: 12px; color: #777"
                >当前已使用云存储空间容量{{ useSpace }}MB</span
              >
            </FormItem>
            <FormItem label="可创建用户数" prop="create_user_num">
              <!-- <Input v-model="EditMemberData.create_user_num"></Input> -->
              <InputNumber
                controls-outside
                :min="useUserNum"
                :precision="0"
                :active-change="false"
                style="width: 100%"
                v-model.trim="EditMemberData.create_user_num"
              ></InputNumber>
              <span style="font-size: 12px; color: #777"
                >当前已添加用户{{ useUserNum }}人</span
              >
            </FormItem>
            <FormItem label="可绑定设备数量" prop="bind_dev_num">
              <!-- <Input v-model="EditMemberData.bind_dev_num"></Input> -->
              <InputNumber
                controls-outside
                :min="useDevNum"
                :precision="0"
                :active-change="false"
                style="width: 100%"
                v-model.trim="EditMemberData.bind_dev_num"
              ></InputNumber>
              <span style="font-size: 12px; color: #777"
                >当前已绑定设备{{ useDevNum }}台</span
              >
            </FormItem>
          </div>
          <FormItem label="是否设置有效期">
            <RadioGroup v-model="EditMemberData.radio">
              <Radio :label="1">是</Radio>
              <Radio :label="0">否</Radio>
            </RadioGroup>
            <DatePicker
              type="date"
              style="margin-left: 40px"
              placeholder="设置有效期"
              format="yyyy-MM-dd"
              v-show="EditMemberData.radio == 1"
              @on-change="editDate"
              :value="EditMemberData.date"
            ></DatePicker>
          </FormItem>
        </Form>
      </div>
      <div style="text-align: center" slot="footer">
        <Button
          type="info"
          @click="compileMember_ok"
          style="width: 200px; letter-spacing: 8px"
          >确认</Button
        >
      </div>
    </Modal>
    <!--删除团队-->
    <Modal v-model="deleteTeamShow" width="360">
      <p slot="header" style="color: #f60; text-align: center">
        <Icon type="ios-information-circle"></Icon>
        <span>删除此团队</span>
      </p>
      <div style="text-align: center">
        <p>
          是否确认删除
          <span style="color: red">{{ this.teamTitle }}</span>
          ?<br />一经删除无法恢复.
        </p>
      </div>
      <div slot="footer">
        <Button type="error" size="large" long @click="TeamDel"
          >确认删除</Button
        >
      </div>
    </Modal>
  </div>
</template>

<script>
import Api from '@/utils/api.js'
import teamTree from './teamTree.vue'
export default {
  name: 'index',
  components: { teamTree },
  data: function () {
    return {
      uploadIconState: false,
      userType: '',
      userInfo: {},
      Project1info: {},
      totalPopulation: 0, //总人口
      teamList: [], //团队列表
      change: 0, //-1 全部  -2自己
      DestroyTree: true,
      columns: [
        {
          title: '头像',
          key: 'head_icon',
          align: 'center',
          width: 100,
          render: (h, params) => {
            return h('div', [
              h('img', {
                attrs: {
                  src: params.row.head_icon,
                  alt: '',
                },
                style: {
                  width: '48px',
                  height: '48px',
                  borderRadius: '24px',
                  textAlign: 'center',
                  display: 'inline-block',
                  marginTop: '5px',
                },
              }),
            ])
          },
        },
        {
          title: '用户名',
          key: 'name',
          align: 'center',
          tooltip: 'true',
        },
        {
          title: '账号',
          key: 'account',
          align: 'center',
          tooltip: 'true',
        },
        // {
        //   title: '电话',
        //   key: 'mobile_phone',
        //   align: 'center',
        //   tooltip: "true",
        // },
        {
          title: '是否启用',
          key: 'action',
          align: 'center',
          render: (h, params) => {
            return h('i-switch', {
              style: {
                display:
                  params.row.user_id == this.userInfo.user_id
                    ? 'none'
                    : 'inline-block',
              },
              props: {
                size: 'large',
                value: params.row.state === 1,
              },
              on: {
                'on-change': () => {
                  this.Change_Member_State(params.row)
                },
              },
              scopedSlots: {
                open: () => h('span', '启用'),
                close: () => h('span', '禁用'),
              },
            })
          },
        },
        {
          title: '权限',
          key: 'is_manager',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              h(
                'span',
                {
                  style: {},
                },
                params.row.is_manager ? '管理员' : '飞手'
              ),
            ])
          },
        },
        {
          title: '操作',
          align: 'center',
          width: 200,
          render: (h, params) => {
            return h('div', [
              h(
                'button',
                {
                  style: {
                    padding: '0px 12px',
                    height: '28px',
                    color: '#fff',
                    fontSize: '14px',
                    borderRadius: '4px',
                    backgroundColor: '#4C98FF',
                    cursor: 'pointer',
                    display:
                      params.row.user_id == this.userInfo.user_id ||
                      this.change == -1
                        ? 'none'
                        : 'inline',
                  },
                  on: {
                    click: () => {
                      this.redact(params.row)
                    },
                  },
                },
                params.row.is_manager === 1 ? '团队编辑' : '编辑'
              ),
              h(
                'button',
                {
                  style: {
                    marginLeft: '20px',
                    padding: '0px 12px',
                    height: '28px',
                    color: '#fff',
                    fontSize: '14px',
                    borderRadius: '4px',
                    backgroundColor: '#F56C6C',
                    cursor: 'pointer',
                    display:
                      params.row.is_manager === 1 || this.change == -1
                        ? 'none'
                        : 'inline',
                  },
                  on: {
                    click: () => {
                      this.$Modal.confirm({
                        title: '系统提示',
                        content: `是否删除  <span style="color: red">${params.row.name}</span>! ,一旦删除无法恢复?`,
                        onCancel: () => {
                          this.$Message.info('点击了取消')
                        },
                        onOk: () => {
                          this.delete(params.row)
                        },
                      })
                    },
                  },
                },
                '删除'
              ),
            ])
          },
        },
      ],
      loading: false,
      MemberList: [], //成员列表,经search函数处理元数据后返回
      metaMemberList: [], // 成员列表元数据,由接口返回
      ChangeMemberState: 0,
      AddTeamBox: false,
      AddMember: false,
      // 表单
      Team: {
        account: '',
        name: '',
        password: '',
        head_icon: '',
        team_name: '',
        storage_space: 0,
        create_user_num: 1,
        bind_dev_num: 0,
        police_license: '',
        mobile_phone: '',
        radio: 0,
        date: '',
      },
      Team_ruleInline: {
        account: [
          { required: true, message: '请填写账号', trigger: 'blur' },
          {
            min: 6,
            max: 12,
            type: 'string',
            pattern: /^[0-9a-zA-Z]+$/,
            message: '请使用长度为6 - 12个字符的字母与数字',
          },
        ],
        name: [
          { required: true, message: '请填写管理员名称', trigger: 'blur' },
          { min: 2, max: 15, message: '管理员名称长度2 - 15个字符' },
        ],
        password: [
          { required: true, message: '请填写密码', trigger: 'blur' },
          {
            type: 'string',
            min: 8,
            max: 20,
            pattern: /^[0-9a-zA-Z]+$/,
            message: '请使用长度为8-20个字符的字母与数字',
          },
        ],
        storage_space: [
          {
            required: true,
            message: '请填写存储空间容量',
            trigger: 'change',
            pattern: /.+/,
          },
        ],
        create_user_num: [
          {
            required: true,
            message: '请填写可创建用户数量',
            trigger: 'change',
            pattern: /.+/,
          },
        ],
        bind_dev_num: [
          {
            required: true,
            message: '请填写可绑定设备数量',
            trigger: 'change',
            pattern: /.+/,
          },
        ],
        team_name: [
          { required: true, message: '请填写团队名称', trigger: 'blur' },
          { min: 2, max: 12, message: '团队名称长度2 - 12个字符' },
        ],
        // mobile_phone: [
        //   { required: true, message: '请填写联系电话', trigger: 'blur' },
        // ],
      },
      selectTeamID: 0,
      project_Member: [], //项目下所有团队成员
      Team_color: ['#FF685B', '#FF9500', '#61D76B', '#4C98FF', '#C179E5'], //原始颜色
      Team_color2: [], //原始颜色
      changeColor: 0,
      // AlreadyHaveMember: {
      //   team_user: [],
      // },
      // AlreadyHaveMember_ruleInline: {
      //   team_user: [{ required: true, message: "请选择添加人员" }],
      // },
      NewMember: {
        client: 'web',
        type: 2,
        account: '',
        name: '',
        password: '',
        pm_id: [],
        permission: false,
        head_icon: '',
        experience_time: '',
        mobile_phone: '',
        police_license: '',
        radio: 0,
        date: '',
      },
      NewMember_ruleInline: {
        account: [
          { required: true, message: '请填写账号', trigger: 'blur' },
          {
            type: 'string',
            min: 6,
            max: 12,
            pattern: /^[0-9a-zA-Z]+$/,
            message: '请使用长度为6 - 12个字符的字母与数字',
          },
        ],
        name: [
          { required: true, message: '请填写用户名', trigger: 'blur' },
          { min: 2, max: 15, message: '用户名长度2 - 15个字符' },
        ],
        password: [
          { required: true, message: '请填写管理员密码', trigger: 'blur' },
          {
            type: 'string',
            min: 8,
            max: 20,
            pattern: /^[0-9a-zA-Z]+$/,
            message: '请使用长度为8 - 20个字符的字母与数字',
          },
        ],

        // mobile_phone: [
        //   { required: true, message: '请填写联系电话', trigger: 'blur' },
        // ],
      },
      addMemberList: [],
      disabledSelect: false,
      uploadList: [],
      compileMemberData: false,
      EditMemberData: {
        name: '',
        manager_id: '',
        head_icon_Edit: '',
        permission: false,
        storage_space: 0,
        create_user_num: 1,
        bind_dev_num: 0,
        police_license: '',
        mobile_phone: '',
        radio: 0,
        date: '',
      },
      EditMemberData_ruleInline: {
        name: [
          { required: true, message: '请填写用户名', trigger: 'blur' },
          { min: 2, max: 15, message: '用户名长度2 - 15个字符' },
        ],
        // mobile_phone: [
        //   { required: true, message: '请填写联系电话', trigger: 'blur' },
        // ],
        bind_dev_num: [
          {
            required: true,
            message: '请填写可绑定设备数量',
            trigger: 'change',
            pattern: /.+/,
          },
        ],
        create_user_num: [
          {
            required: true,
            message: '请填写可创建用户数量',
            trigger: 'change',
            pattern: /.+/,
          },
        ],
        storage_space: [
          {
            required: true,
            message: '请填写存储空间容量',
            trigger: 'change',
            pattern: /.+/,
          },
        ],
      },
      uploadList2: [],
      manager_whether: '飞手',
      CheckedEditMember: 0, //点编辑_选中的成员id
      EditManagerShow: false,
      deleteTeamShow: false, //删除团队弹框是否显示
      deleteTeamData: {}, //要删除的团队数据
      ListTitle: '团队',
      teamName: '',
      teamListUpLoad: 0,
      creationJurisdiction: '',
      teamTitle: '', // 成员部分显示的团队标题和人数
      remainingDevNum: 0, // 团队剩余可绑定设备数量
      remainingUserNum: 0, // 团队剩余可添加用户数量
      remainingSpace: 0, // 团队剩余可分配云空间
      useDevNum: 0, // 团队已绑定设备数量
      useUserNum: 0, // 团队已添加用户数量
      useSpace: 0, // 团队已分配云空间
      sumSpace: 0, // 团队总云空间容量
      sumDevNum: 0, // 团队总设备数量
      sumUserNum: 0, // 团队总飞手数量
      upTeam: 0, // 选中团队的上级团队id
      upTeamDate: '', // 选中团队的上级团队有效期
      teamDate: '', // 选中团队的有效期
      searchtext: '', // 搜索内容
    }
  },
  created() {
    if (this.$CSTJ) {
      this.columns.splice(3, 0, {
        title: '警航编号',
        key: 'police_license',
        align: 'center',
        tooltip: 'true',
      })
      this.Team_ruleInline.police_license = [
        { required: true, message: '请填写警航执照', trigger: 'blur' },
      ]
      this.NewMember_ruleInline.police_license = [
        { required: true, message: '请填写警航执照', trigger: 'blur' },
      ]
      this.EditMemberData_ruleInline.police_license = [
        { required: true, message: '请填写警航执照', trigger: 'blur' },
      ]
    }
  },
  mounted() {
    this.userType = sessionStorage.getItem('userType')
    this.userInfo = JSON.parse(sessionStorage.getItem('user_info'))

    this.Project1info = JSON.parse(sessionStorage.getItem('Project1info'))
    // this.loadTeamList();
    this.getTeamList()
    //进页面默认显示登陆者用户
    setTimeout(() => {
      this.selectOneself()
    }, 300)

    // this.$_bus.$off("TeamListPushChildren");
    // this.$_bus.$on("TeamListPushChildren", function (val) {
    //   _this.TeamListPushChildren(val);
    // });
    // this.$_bus.$off("teamListChange");
    // this.$_bus.$on("teamListChange", function (val) {
    //   _this.change = val.id;
    //   _this.teamListChange(val.id);
    // });

    if (this.userType == 0) {
      this.columns.pop()
    }
  },
  methods: {
    // 选择了团队
    selectTeam(ind, title, up_team) {
      this.change = ind
      this.teamTitle = title
      this.upTeam = up_team
      this.getTeamStaffData()
      let Items = document.getElementsByClassName('Item')
      for (let i = 0; i < Items.length; i++) {
        if (this.$store.state.darkTheme) {
          Items[i].style.backgroundColor = '#1F1F1F'
        } else {
          Items[i].style.backgroundColor = '#f0f0f0'
        }
      }

      //   this.teamList.forEach((item) => {
      //     if (item.id == data.id) {
      //       this.teamName = data.team_name;
      //       this.MemberList = item.teamuser;
      //       this.selectTeamID = data.id;
      //     }
      //   });
    },
    // 选择了全部,显示全部飞手
    selectAll() {
      this.change = -1
      this.teamListChange(-1)
      // this.reloadTree();
      this.$post(Api.getAllTeamUser()).then((res) => {
        // console.log(res.data);
        // this.MemberList = [];
        // this.metaMemberList = res.data;
        this.MemberList = res.data
      })
    },
    //显示当前登录账号下的所有用户
    selectOneself() {
      this.change = this.userInfo.team_id
      this.teamTitle = this.userInfo.team_name
      // this.reloadTree();
      // sessionStorage.setItem("teamListChangeId", this.userInfo.team_id);
      this.teamListChange(this.userInfo.team_id)
    },
    // 改变团队成员是否停用状态
    Change_Member_State(row) {
      this.$post(Api.switchFlyerState(), {
        type: 1,
        state: row.state == 1 ? 0 : 1,
        user_id: row.user_id,
      }).then((res) => {
        if (res.code == 1) {
          this.getTeamStaffData()
          this.$Message.success(res.msg_customer)
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
    // 编辑
    redact(row) {
      this.compileMemberData = true
      this.CheckedEditMember = row.user_id
      if (row.is_manager == 1) {
        //管理员
        this.EditManagerShow = true
      } else {
        //飞手
        this.EditManagerShow = false
      }
      // 有效期
      if (row.experience_time) {
        this.EditMemberData.radio = 1
        this.EditMemberData.date = row.experience_time
      }
      this.EditMemberData.name = row.name
      this.EditMemberData.head_icon_Edit = row.head_icon
      this.EditMemberData.permission = row.permission == 0 ? false : true
      //   this.EditMemberData.storage_space = row.storage_space;
      //   this.EditMemberData.create_user_num = row.create_user_num;
      //   this.EditMemberData.bind_dev_num = row.bind_dev_num;
      this.EditMemberData.bind_dev_num = this.sumDevNum
      this.EditMemberData.create_user_num = this.sumUserNum
      this.EditMemberData.storage_space = this.sumSpace
      if (this.$CSTJ) {
        this.EditMemberData.police_license = row.police_license
      }
      if (this.$public) {
        this.EditMemberData.mobile_phone = row.mobile_phone
      }
      console.log('头像', this.EditMemberData.head_icon_Edit)
    },
    compileMember_ok() {
      this.$refs['EditMemberData'].validate((valid) => {
        if (valid) {
          let data = {
            user_id: this.CheckedEditMember,
            name: this.EditMemberData.name,
            head_icon: this.EditMemberData.head_icon_Edit,
            // head_icon: null,
          }
          if (!this.uploadIconState) {
            data.head_icon = null
          }
          if (this.$public) {
            data.mobile_phone = this.EditMemberData.mobile_phone
          }
          if (this.$CSTJ) {
            data.police_license = this.EditMemberData.police_license
          }
          if (this.EditManagerShow) {
            // 团队编辑
            // if (this.EditMemberData.storage_space == null) {
            //   this.$Message.error('云存储空间容量不能为空');
            //   return;
            // }
            // if (this.EditMemberData.create_user_num == null) {
            //   this.$Message.error('可创建用户数量不能为空');
            //   return;
            // }
            // if (this.EditMemberData.bind_dev_num == null) {
            //   this.$Message.error('可绑定设备数量不能为空');
            //   return;
            // }
            data.storage_space = this.EditMemberData.storage_space
            data.create_user_num = this.EditMemberData.create_user_num
            data.bind_dev_num = this.EditMemberData.bind_dev_num
            if (this.EditMemberData.radio == 1) {
              if (this.EditMemberData.date == '') {
                this.$Message.error('请选择账号有效期')
                return false
              } else {
                data.date = this.EditMemberData.date
              }
            } else {
              if (this.upTeamDate == null) {
                data.date = null
              } else {
                data.date = this.upTeamDate + ' 23:59:59'
              }
            }
          } else {
            // 飞手编辑
            data.permission = this.EditMemberData.permission ? 1 : 0
            if (this.EditMemberData.radio == 1) {
              if (this.EditMemberData.date == '') {
                this.$Message.error('请选择账号有效期')
                return false
              } else {
                data.date = this.EditMemberData.date
              }
            } else {
              if (this.teamDate === null) {
                data.date = null
              } else {
                data.date = this.teamDate + ' 23:59:59'
              }
            }
          }
          this.$post(Api.userEdit(), data).then((res) => {
            if (res.code == 1) {
              this.compileMemberData = false
              // this.$refs["EditMemberData"].resetFields();
              this.EditMemberData = {
                name: '',
                manager_id: '',
                head_icon_Edit: '',
                permission: false,
                storage_space: 0,
                create_user_num: 1,
                bind_dev_num: 0,
                radio: 0,
                date: '',
              }
              this.uploadIconState = false
              this.searchtext = ''
              this.$Message.success(res.msg_customer)
              this.getTeamStaffData()
            } else {
              this.$Message.error(res.msg_customer)
            }
          })
        }
      })
    },
    // // 把飞手移出团队
    // remove(row) {
    //   this.$post("/admin/Team/removeTeamUser", {
    //     user_id: row.user_id,
    //     team_id: this.selectTeamID,
    //   }).then((res) => {
    //     if (res.code == 1) {
    //       this.ReloadData();
    //       this.$Message.info(res.msg_customer);
    //     } else {
    //       this.$Message.error(res.msg_customer);
    //     }
    //   });
    // },
    // 新增团队的有效期
    addDate(e) {
      if (e) {
        if (new Date(e) <= new Date(this.teamDate) || this.teamDate == null) {
          this.Team.date = e + ' 23:59:59'
        } else {
          this.$Message.warning('团队有效期不能超过上级团队有效期')
          this.Team.date = this.upTeamDate + ' 23:59:59'
        }
      }
    },
    // 新增成员的有效期
    newDate(e) {
      if (new Date(e) <= new Date(this.teamDate) || this.teamDate == null) {
        this.NewMember.date = e + ' 23:59:59'
      } else {
        this.$Message.warning('成员有效期不能超过团队有效期')
        this.NewMember.date = this.teamDate + ' 23:59:59'
      }
    },
    // 编辑成员的有效期,注意区分管理员和飞手
    editDate(e) {
      if (e) {
        if (this.EditManagerShow) {
          if (new Date(e) <= new Date(this.upTeamDate)) {
            this.EditMemberData.date = e + ' 23:59:59'
          } else {
            this.$Message.warning('团队有效期不能超过上级团队有效期')
            this.EditMemberData.date = this.upTeamDate + ' 23:59:59'
          }
        } else {
          if (new Date(e) <= new Date(this.teamDate)) {
            this.EditMemberData.date = e + ' 23:59:59'
          } else {
            this.$Message.warning('成员有效期不能超过团队有效期')
            this.EditMemberData.date = this.teamDate + ' 23:59:59'
          }
        }
      }
    },
    // 删除飞手账号
    delete(row) {
      this.$post(Api.userDel(), {
        user_id: row.user_id,
      }).then((res) => {
        if (res.code == 1) {
          this.getTeamStaffData()
          // this.reloadTree();
          this.$Message.info(res.msg_customer)
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
    // 获取添加团队里的人员数据
    // get_addTeam_Member_Data() {
    //   this.$post("/admin/Team/getUserList").then((res) => {
    //     // console.log("人员数据",res);
    //     if (res.code == 1) {
    //       this.project_Member = res.data.userlist;
    //       this.Team_color2 = this.Team_color.filter(function (v) {
    //         return res.data.teamcolor.indexOf(v) == -1;
    //       });
    //       this.Team.colour = this.Team_color2[0];
    //     } else {
    //       if (
    //               this.user_type !== 0 &&
    //               res.msg_customer == "非工程管理员无法查看"
    //       ) {
    //         return;
    //       } else {
    //         this.$Message.error(res.msg_customer);
    //       }
    //     }
    //   });
    // },

    // 选择团队颜色
    // selectColor(item, ind) {
    //   this.changeColor = ind;
    //   this.Team.colour = item;
    // },

    // 添加团队
    AddTeam_ok() {
      this.$refs['Team'].validate((valid) => {
        if (valid) {
          let data = {
            client: 'web',
            account: this.Team.account,
            name: this.Team.name,
            password: this.Team.password,
            head_icon: '',
            team_name: this.Team.team_name,
            storage_space: this.Team.storage_space,
            create_user_num: this.Team.create_user_num,
            bind_dev_num: this.Team.bind_dev_num,
            up_team: this.change,
          }
          if (this.$public) {
            data.mobile_phone = this.Team.mobile_phone
          }
          if (this.$CSTJ) {
            data.police_license = this.Team.police_license
          }
          if (this.Team.radio == 1) {
            if (this.Team.date == '') {
              this.$Message.error('请选择账号有效期')
              return false
            } else {
              data.date = this.Team.date
            }
          } else {
            if (this.upTeamDate === null) {
              data.date = null
            } else {
              data.date = this.upTeamDate + ' 23:59:59'
            }
          }
          if (this.NewMember.head_icon !== '') {
            data.head_icon = this.NewMember.head_icon
          }
          this.$post(Api.addTeam(), data).then((res) => {
            if (res.code == 1) {
              this.$Message.info(res.msg_customer)
              this.AddTeamBox = false
              //   this.$refs['Team'].resetFields();
              this.cancelAddTeam()
              this.reloadTree()
              this.getTeamList()
              // 更新团队选择组件
              this.$_bus.$emit('team_refresh')
            } else {
              this.$Message.error(res.msg_customer)
            }
          })
        }
      })
    },
    // 取消团队增加
    cancelAddTeam() {
      this.$refs['Team'].resetFields()
      this.$refs['EditMemberData'].resetFields()
      this.uploadList = []
      this.uploadList2 = []
      this.EditMemberData.bind_dev_num = this.useDevNum
      this.EditMemberData.create_user_num = this.useUserNum
      this.EditMemberData.storage_space = this.useSpace
      this.Team.radio = 0
      this.EditMemberData.radio = 0
      this.Team.date = ''
      this.EditMemberData.date = ''
    },
    // 添加团队成员
    AddMember_ok() {
      let _this = this
      //新建人员
      this.$refs['NewMember'].validate((valid) => {
        if (valid) {
          let data = {}
          if (_this.change == _this.userInfo.team_id) {
            data = {
              client: 'web',
              account: _this.NewMember.account,
              name: _this.NewMember.name,
              password: _this.NewMember.password,
              permission: _this.NewMember.permission ? 1 : 0,
              up_team: _this.userInfo.team_id,
            }
          } else {
            data = {
              client: 'web',
              account: _this.NewMember.account,
              name: _this.NewMember.name,
              password: _this.NewMember.password,
              permission: _this.NewMember.permission ? 1 : 0,
              up_team: _this.change,
            }
          }
          if (_this.$public) {
            data.mobile_phone = _this.NewMember.mobile_phone
          }
          if (_this.$CSTJ) {
            data.police_license = _this.NewMember.police_license
          }

          if (_this.NewMember.head_icon !== '') {
            data.head_icon = _this.NewMember.head_icon
          }
          if (this.NewMember.radio == 1) {
            if (this.NewMember.date == '') {
              this.$Message.error('请选择账号有效期')
              return false
            } else {
              data.date = this.NewMember.date
            }
          } else {
            if (this.teamDate === null) {
              data.date = null
            } else {
              data.date = this.teamDate + ' 23:59:59'
            }
          }
          _this.$post(Api.addUser(), data).then((res) => {
            // console.log(res);
            if (res.code == 1) {
              _this.AddMember = false
              _this.NewMember = {
                client: 'web',
                type: 2,
                account: '',
                name: '',
                password: '',
                pm_id: [],
                permission: false,
                head_icon: '',
                experience_time: '',
                mobile_phone: '',
                police_license: '',
                radio: 0,
                date: '',
              }
              _this.searchtext = ''
              _this.$Message.success(res.msg_customer)
              _this.getTeamStaffData()
              _this.cancelAddMember()
            } else {
              _this.$Notice.error({
                title: '提示',
                desc: res.msg_customer,
              })
            }
          })
        } else {
          this.$Message.error('请正确填写表单')
        }
      })
    },
    // 取消成员增加
    cancelAddMember() {
      this.$refs['NewMember'].resetFields()
      this.NewMember.radio = 0
      this.NewMember.date = ''
      this.uploadList = []
    },
    // 新增用户的头像上传
    handleFormatError(file) {
      this.$Notice.warning({
        title: '文件格式不正确',
        desc: '文件格式请选择jpg或png.',
      })
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: '超过限定大小限制',
        desc: '上传的图片大小不得大于2M.',
      })
    },
    //上传用户头像
    uploadBefore(file) {
      if (file) {
        let fm = new FormData()
        fm.append('client', 'web')
        fm.append('file', file)
        fm.append('use_type', 2)
        this.$fromdata(Api.headIconUpload(), fm).then((res) => {
          if (res.code == 1) {
            this.NewMember.head_icon = res.data.head_icon
            this.uploadList.push({
              status: 'finished',
              url: res.data.head_icon,
            })
          } else {
            this.$Message.error(res.msg_customer)
          }
        })
      }
    },
    // 重置此头像
    handleRemove(item) {
      // console.log("点击了删除");
      this.uploadList.splice(this.uploadList.indexOf(item), 1)
    },
    // 编辑弹框的头像上传=====================
    //上传用户头像
    uploadBefore_Edit(file) {
      if (file) {
        let fm = new FormData()
        fm.append('client', 'web')
        fm.append('file', file)
        fm.append('use_type', 2)
        this.$fromdata(Api.headIconUpload(), fm).then((res) => {
          if (res.code == 1) {
            this.EditMemberData.head_icon_Edit = res.data.head_icon
            // this.uploadList2.push({
            //   status: "finished",
            //   url: res.data.head_icon,
            // });
          } else {
            this.$Message.error(res.msg_customer)
          }
        })
        this.uploadIconState = true
      }
    },
    // 重置此头像
    handleRemove_Edit(item) {
      // console.log("点击了删除");
      this.uploadList2.splice(this.uploadList2.indexOf(item), 1)
    },
    // 删除团队
    TeamDel() {
      this.$post(Api.removeTeam(), {
        team_id: this.change,
        up_team: this.upTeam,
        storage_space: parseFloat(this.sumSpace),
      }).then((res) => {
        if (res.code == 1) {
          this.deleteTeamShow = false //删除团队弹框是否显示
          // this.getTeamList();
          this.reloadTree()
          this.selectOneself()
          // if (this.userType == 0) {
          //   this.columns.pop();
          // }
          // 更新团队选择组件
          this.$_bus.$emit('team_refresh')
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
    //+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    //获取团队列表
    getTeamList() {
      this.$post(Api.teamList(), {
        team_id: this.userInfo.team_id,
      }).then((res) => {
        if (res.code == 1) {
          if (res.data.length > 0) {
            this.teamList = res.data[0].child_team
          }
        } else {
          this.$Message.error(res.msg_customer)
        }
      })
    },
    // //团队列表动态加载的子数据添加到总数据中
    // TeamListPushChildren(val) {
    //   var _this = this;
    //   // console.log(val);

    //   function aaa(data) {
    //     data.forEach((item, ind) => {
    //       if (item.id == val.id) {
    //         _this.$nextTick(() => {
    //           data[ind].children = val.child_team;
    //         });
    //       } else if (data[ind].children) {
    //         aaa(data[ind].children);
    //       }
    //     });
    //   }
    //   aaa(this.teamList);
    //   this.teamListUpLoad = this.teamListUpLoad + 1;
    //   // console.log(this.teamList);
    // },
    //团队列表选中高亮  请求人员数据
    teamListChange(val = 0) {
      let Items = document.getElementsByClassName('Item')
      // 清除tree组件中的选中状态
      this.$refs.teamTreeComponent.clearSelect()
      for (let i = 0; i < Items.length; i++) {
        if (this.$store.state.darkTheme) {
          Items[i].style.backgroundColor = '#1F1F1F'
        } else {
          Items[i].style.backgroundColor = '#f0f0f0'
        }
      }

      if (val > 0) {
        if (this.$store.state.darkTheme) {
          document.getElementById('TeamList' + val).style.backgroundColor =
            '#3C3D43'
        } else {
          document.getElementById('TeamList' + val).style.backgroundColor =
            '#d6d6d6'
        }

        this.getTeamStaffData()
      }
    },

    //请求团队人员数据
    getTeamStaffData() {
      if (this.change === -1) {
        this.selectAll()
      } else {
        this.$post(Api.getTeamUser(), {
          vague_name: this.searchtext,
          team_id: this.change,
        }).then((res) => {
          // console.log(res.data);
          //   this.MemberList = [];
          //   this.metaMemberList = res.data;
          this.MemberList = res.data
        })
        this.$post(Api.getTeamInformation(), {
          team_id: this.change,
        }).then((res) => {
          this.remainingDevNum = res.data.remaining_dev_num
          this.remainingUserNum = res.data.remaining_user_num
          this.remainingSpace = res.data.remaining_space
          this.useDevNum = res.data.use_dev_num
          this.useUserNum = res.data.use_user_num
          this.useSpace = res.data.use_space
          this.sumSpace = res.data.storage_space
          this.sumDevNum = res.data.bind_dev_num
          this.sumUserNum = res.data.create_user_num
          this.teamDate = res.data.date
          this.upTeamDate = res.data.upDate
        })
      }
    },

    // 重新加载teamTree组件
    reloadTree() {
      this.DestroyTree = false
      this.$nextTick(() => {
        this.DestroyTree = true
      })
    },
  },
  //   watch: {
  //     manager_whether(val) {
  //       if (val == '管理员') {
  //         this.EditMemberData.manager_id = 1;
  //       } else {
  //         this.EditMemberData.manager_id = 0;
  //       }
  //     },
  //     // change(val) {
  //     //   if (val < 0) {
  //     //     sessionStorage.setItem("teamListChangeId", this.userInfo.team_id);
  //     //   }
  //     // },
  //   },
}
</script>

<style scoped lang="scss">
#team {
  background-color: $xf_hei2;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: -webkit-box;
  //   display: flex;
  //   justify-content: space-between;
  padding: 14px;
  /deep/.ivu-tree ul {
    background: $xf_hui1;
    color: $white;
  }
  /deep/.ivu-tree-title {
    color: $white;
  }
  /deep/.ivu-tree-title-selected,
  .ivu-tree-title-selected:hover {
    -webkit-box-shadow: -80px 0 0 $xf_hui2_2;
    box-shadow: -80px 0 0 $xf_hui2_2;
    background-color: $xf_hui2_2;
  }
  /deep/.ivu-tree-title:hover {
    background-color: $xf_hui2_2;
  }
  .teamList {
    width: 394px;
    height: 100%;
    margin-right: 10px;
    position: relative;
    .ListTop {
      width: 100%;
      height: 60px;
      background-color: $xf_hui1;
      color: $white;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 30px;
      h1 {
        font-size: 18px;
      }
      i {
        font-size: 26px;
        color: #409eff;
        font-weight: bold;
        cursor: pointer;
      }
    }
    .ListContent {
      width: 100%;
      user-select: none; // 禁用鼠标选中文本
      position: absolute;
      top: 60px;
      bottom: 0px;
      left: 0px;
      background-color: $xf_hui1;
      border-top: 1px solid $xf_hui3;
      // box-shadow: 0px 3px 6px #ddd;
      // border-radius: 4px;
      overflow: auto;
      padding: 16px 12px;
      .itemBox {
        > div {
          display: flex;
          width: 100%;
          height: 72px;
          justify-content: space-between;
          align-items: center;
          padding: 0 16px;
          margin-bottom: 6px;
          cursor: pointer;
          background-color: $xf_hui1;
          color: $white;
          font-size: 18px;
          &:hover {
            background-color: $xf_hui2_2;
          }
          .name {
            display: flex;
            align-items: center;
            width: 330px;
            i {
              font-size: 20px;
              margin-right: 10px;
            }
            > div {
              width: 12px;
              height: 12px;
              border-radius: 6px;
              margin-right: 20px;
            }
          }
          h1 {
            font-size: 18px;
          }
          .num {
            width: 20%;
            display: flex;
            justify-content: flex-end;
          }
          h2 {
            font-size: 14px;
            i {
              font-size: 14px;
              color: #df0038;
            }
          }
          .select {
            background-color: #edf9ff;
          }
        }

        .itemChildren {
          width: 80%;
        }
      }
    }
  }
  .teamMember {
    width: 1388px;
    height: 100%;
    position: relative;
    .MemberTop {
      width: 100%;
      height: 60px;
      background-color: $xf_hui4;
      color: $white;
      // box-shadow: 0px 3px 6px #ddd;
      // border-radius: 4px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      h1 {
        font-size: 24px;
      }
      i {
        font-size: 24px;
        color: #409eff;
        cursor: pointer;
      }
      .team-member-button {
        width: 82px;
        height: 35px;
        border-radius: 2px;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
      }
      .borderRadius {
        border-radius: 18px;
      }
    }
    .MemberContent {
      width: 100%;
      position: absolute;
      top: 60px;
      bottom: 0px;
      left: 0px;
      background-color: $xf_hui1;
      border-top: 1px solid $xf_hui3;
      // box-shadow: 0px 3px 6px #ddd;
      // border-radius: 4px;
      overflow: auto;
      padding: 5px;
    }
  }
}
/deep/.team-center-modal {
  .AddTeamContent {
    display: flex;
    justify-content: space-between;
    > div {
      width: 260px;
    }
  }
}
// /deep/ .ivu-input {
//   width: 250px;
// }
/deep/ .ivu-input-wrapper {
  width: 100% !important;
}
/deep/ .ivu-form-item-content {
  .upload-list {
    display: flex;
    align-items: center;
    img {
      width: 60px;
      height: 60px;
    }
    .upload-list-cover {
      margin-left: 15px;
    }
  }
}
/deep/.AddMember-center-modal {
  .upload-list {
    display: inline-block;
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    border: 1px solid transparent;
    border-radius: 4px;
    overflow: hidden;
    background: $xf_hui1;
    position: relative;
    box-shadow: 0 3px 6px #eee;
    margin-right: 4px;
  }
  .upload-list img {
    width: 100%;
    height: 100%;
  }
  .upload-list-cover {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
  }
  .upload-list:hover .upload-list-cover {
    display: block;
  }
  .upload-list-cover i {
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    margin: 0 2px;
  }
  .select {
    background-color: #edf9ff;
  }
}
</style>
