<template>
  <div>
    <Tree :data="data3"
          :load-data="loadData"
          @on-select-change="selectTeam"></Tree>
  </div>
</template>

<script>
import Api from '@/utils/api.js';
export default {
  data() {
    return {
      data3: [],
      c: '',
    };
  },
  mounted() {
    this.userInfo = JSON.parse(sessionStorage.getItem('user_info'));

    this.$post(Api.teamList(), {
      team_id: this.userInfo.team_id,
    }).then((res) => {
      if (res.code == 1) {
        if (res.data.length > 0) {
          res.data[0].child_team.forEach((element) => {
            this.data3.push({
              title: element.label,
              loading: false,
              children: [],
              team_id: element.value,
              up_team: element.up_team,
              // user_num: element.user_num,
              render: (h, { root, node, data }) => {
                return h(
                  'span',
                  {
                    style: {
                      // display: "inline-block",
                      width: '100%',
                      // height: "72px",
                      // lineHeight: "72px",
                    },
                  },
                  data.title
                  // [
                  //   h("span", data.title),
                  //   h(
                  //     "span",
                  //     {
                  //       style: {
                  //         display: "inline-bolck",
                  //         float: "right",
                  //         marginRight: "10px",
                  //       },
                  //     },
                  //     element.user_num + "人"
                  //   ),
                  // ]
                );
              },
            });
          });
        }
      } else {
        this.$Message.error(res.msg_customer);
      }
    });
  },
  methods: {
    loadData(item, callback) {
      const data = [];
      this.$post(Api.teamList(), {
        team_id: item.team_id,
      }).then((res) => {
        if (res.code == 1) {
          if (res.data[0].child_team.length > 0) {
            res.data[0].child_team.forEach((element) => {
              data.push({
                title: element.label,
                loading: false,
                children: [],
                team_id: element.value,
                up_team: element.up_team,
                render: (h, { root, node, data }) => {
                  return h(
                    'span',
                    {
                      style: {
                        // display: "inline-block",
                        width: '100%',
                      },
                    },
                    data.title
                    // [
                    //   h("span", data.title),
                    //   h(
                    //     "span",
                    //     {
                    //       style: {
                    //         display: "inline-bolck",
                    //         float: "right",
                    //         marginRight: "10px",
                    //       },
                    //     },
                    //     element.user_num + "人"
                    //   ),
                    // ]
                  );
                },
              });
            });
            callback(data);
          } else {
            this.$Message.info('该团队无下级团队');
            callback(data);
          }
        } else {
          this.$Message.error(res.msg_customer);
        }
      });
    },
    // 选中团队
    selectTeam(treeArr, teamData) {
      //   console.log('treeArr', treeArr);
      if (treeArr.length > 0) {
        // 设置选中
        this.$emit(
          'selectTeam',
          teamData.team_id,
          teamData.title,
          teamData.up_team
        );
      }
      // console.log(teamData.team_id);
    },
    //
    clearSelect() {
      let Items = document.getElementsByClassName('ivu-tree-title-selected');
      for (let i = 0; i < Items.length; i++) {
        Items[i].classList.remove('ivu-tree-title-selected');
      }
    },
  },
};
</script>

<style scoped>
.ivu-tree /deep/ {
  overflow: hidden;
}
.ivu-tree /deep/ ul {
  padding-left: 15px;
  line-height: 70px;
  background-color: #fafafa;
}
.ivu-tree /deep/ .ivu-tree-title {
  /* display: unset; */
  width: 100%;
}
/* .ivu-tree /deep/ .ivu-tree-title:hover {
  box-shadow: -80px 0 0 #eaf4fe;
} */
.ivu-tree /deep/ .ivu-tree-title-selected,
.ivu-tree-title-selected:hover {
  box-shadow: -80px 0 0 #d5e8fc;
}
.ivu-tree /deep/ .ivu-tree-arrow {
  position: relative;
  width: 20px;
}
.ivu-tree /deep/.ivu-tree-arrow i {
  vertical-align: text-bottom;
  font-size: 20px;
}
.ivu-tree /deep/ .ivu-tree-empty {
  text-align: center;
  margin-top: 30px;
  font-size: 14px;
  color: #ccc;
}
</style>